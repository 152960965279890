<template>
  <div>
        <!-- Filter Section -->
    <b-card title="Filters" class="mb-0">
      <b-form @submit.prevent>
        <b-row>
          <!-- Date Start -->
          <b-col cols="12" md="2">
                <b-form-group
                  :label="$t('apps.reportCashAdvance.singular.dateStart')"
                  label-for="date-start"
                >
                    <flat-pickr
                        id="date-start"
                        :config="dateFormat"
                        v-model="form.dateStart"
                        class="form-control form-control-sm"
                    />
                </b-form-group>
          </b-col>
          <!-- Date End -->
          <b-col cols="12" md="2">
              <b-form-group
                :label="$t('apps.reportCashAdvance.singular.dateEnd')"
                label-for="date-start"
              >
                <flat-pickr
                    id="date-end"
                    :config="dateFormat"
                    v-model="form.dateEnd"
                    class="form-control form-control-sm"
                  />
            </b-form-group>
          </b-col>
          <!-- Supplier -->
          <b-col cols="12" md="3">
            <b-form-group
              :label="$t('globalSingular.supplier')"
              label-for="supplier-id"
            >
                    <v-select
                        id="supplier-id"
                        class="select-size-sm"
                        v-model="form.supplier"
                        :options="LOV.supplier"
                        :reduce="field => field.id"
                        :getOptionLabel="option => `${option.code} - ${option.pic_name}`"
                      />
            </b-form-group>
          </b-col>
           <!-- Button Filter -->
          <b-col cols="12" md="2">
            <b-button block variant="primary" size="sm" @click="getReport" class="mt-2 pd-1">
              {{ $t('globalActions.find') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <!-- button print -->
    <div class="mt-1 mb-1 d-flex justify-content-end">
        <b-button variant="outline-primary" @click="print">
           <feather-icon icon="FileIcon" />
            {{ $t('globalActions.print') }}
        </b-button>
            <!-- button print export  -->
            <b-button-group class="ml-1">
                <b-dropdown right variant="outline-primary">
                    <template #button-content>
                        <feather-icon icon="DownloadIcon"/>
                        {{ $t('globalActions.export') }}
                    </template>
                  
                  <b-dropdown-item-button @click="exportToPDF">PDF</b-dropdown-item-button>  
                  <b-dropdown-item-button @click="exportToExcel">Excel</b-dropdown-item-button>

                </b-dropdown>
            </b-button-group>
    </div>
    <!-- table container card  -->
    <b-card no-body id="pdfDom">
        <b-card-body class="text-center">
          <b-card-title>{{ companyName }}</b-card-title>
          <b-card-sub-title>
            <div>Master PR</div>
            <div class="mt-sm-1">{{ periodReportText }}</div>
          </b-card-sub-title>
        </b-card-body>

        <!-- isi table  -->
        <b-table
          id="refListTable"
          ref="refListTable"
          no-provider-paging
          no-provider-filtering
          :filter-included-fields="columnToBeFilter"
          :fields="tableColumns"
          :items="itemLists"
          :filter="searchQuery"
          responsive
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          :busy="isBusy"
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative"
        >
        </b-table>
    </b-card>
  </div>
</template>
<script>
import {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BButtonGroup,
    BDropdown,
    BDropdownItemButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BTable
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { ref, onMounted } from '@vue/composition-api'
import { formatDatepicker, unformatNumber } from '@/utils/formatter'
import vSelect from 'vue-select'
import useFormResource from '@/comp-functions/useFormResource'
import useListTable from '@/comp-functions/useListTable'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    flatPickr,
    vSelect,
    BButton,
    BButtonGroup,
    BDropdown,
    BDropdownItemButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BTable
  },
  setup () {
    const form = ref({
      dateStart: formatDatepicker(Date.now()),
      dateEnd: formatDatepicker(Date.now()),
      supplier: ''
    })

    const {
        refListTable,
        itemLists,
        searchQuery,
        sortBy,
        isBusy,
        isSortDirDesc
    } = useListTable({ url: false })

    const { get } = useFormResource({ url: '' })


    const dateFormat = ref({ dateFormat: 'd/m/Y', allowInput: true })
    const LOV = ref({
      supplier: []
    })

    // Table Handlers
    const tableColumns = [
      { key: 'tgl_terima', label: 'Tgl Terima' },
      { key: 'no_pr', label: 'No. PR' },
      { key: 'no_po', label: 'No. PO' },
      { key: 'statu_po', label: 'Status PO' },
      { key: 'name_input', label: 'Name Input' },
      { key: 'department', label: 'Departement' },
      { key: 'pic_name', label: 'Peruntukan (PIC Name)' },
      { key: 'total_po', label: 'Total PO' },
      { key: 'supplier', label: 'Supplier' },
      { key: 'product', label: 'Product' },
      { key: 'tgl_terima_gr', label: 'Tanggal Terima Barang(GR)' },
      { key: 'penerima_gr', label: 'Penerima GR' }
    ]

    // Table Handlers
    const columnToBeFilter = [
      'code',
      'employee'
    ]

    const periodReportText = ref()

    const getSupplier = async () => {
      LOV.value.supplier = await get({ url: 'value/contact/supplier'})
    }

    const updatePeriodText = () => {
      periodReportText.value = `${form.value.dateStart} to ${form.value.dateEnd}`
    }


    onMounted(async () => {
      await getSupplier()
      updatePeriodText()
      
    })


    return {
        form,
        dateFormat,
        LOV,
        periodReportText,
        refListTable,
        columnToBeFilter,
        tableColumns,
        itemLists,
        searchQuery,
        sortBy,
        isBusy,
        isSortDirDesc
    }
  },
  methods: {
    getReport() {
        alert('berhasil')
    //     const params = [
    //     `dateStart=${this.form.dateStart}`,
    //     `dateEnd=${this.form.dateEnd}`,
    //     `supplier=${this.form.supplier ?? ''}`
    //   ]
    //   const url = `reports/cash-advance?${ params.join('&') }`
    //   await fetchLists(url)
    },
    exportToPDF () {
      this.$nextTick(() => this.getPdf())
    },
    exportToExcel () {
      import('@/vendors/Export2Excel').then(excel => {
        const list = this.itemLists

        // add title
        const headerTitle = this.tableColumns.map(column => column.label)
        // only take indexData from columnDef,
        const headerVal = this.tableColumns.map(column => column.key)
        const data = this.formatJson(headerVal, list).map((row) => {
          return row.map((cell) => {
            return unformatNumber(cell);
          })
        });
        excel.export_json_to_excel({
          multiHeader: [
            [this.$store.state.user.currentCompany.name, '', '', '', '', '', ''],
            ['Cash Advance Report', '', '', '', '', '', ''],
            [this.periodReportText, '', '', '', '', '', ''],
            ['', '', '', '', '', '', '']
          ],
          header: headerTitle,
          data,
          merges: [
            { s: { c: 0, r: 0 }, e: { c: 5, r: 0 } },
            { s: { c: 0, r: 1 }, e: { c: 5, r: 1 } },
            { s: { c: 0, r: 2 }, e: { c: 5, r: 2 } },
            { s: { c: 0, r: 3 }, e: { c: 5, r: 3 } }
          ],
          filename: `Cash Advance Report ${this.periodReportText}`,
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
  }
  
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .dropdown-menu .dropdown-item {
    width: 100%;
  }

  @media print {
    #app {
      * {
        visibility: hidden;
        margin: 0 !important;
      }

      #pdfDom,
      #pdfDom * {
        visibility: visible;
      }

      #pdfDom {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        margin-top: 100px;
      }
    }
  }

  @page {
    size: auto;
  }
</style>
